
import * as ipxRuntime$FBy1B3BTKp from '/builds/robustastudio/mazaya/parfois-v3/node_modules/.pnpm/@nuxt+image@1.0.0-rc.1_rollup@2.79.1/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "www.parfoisegypt.com"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$FBy1B3BTKp, defaults: {"domains":["https://www.parfoisegypt.com"],"name":"ipx","provider":"/builds/robustastudio/mazaya/parfois-v3/node_modules/.pnpm/@nuxt+image@1.0.0-rc.1_rollup@2.79.1/node_modules/@nuxt/image/dist/runtime/providers/ipx"} }
}
        